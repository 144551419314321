/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  AccountFormWrap,
  FifthRows,
  FirstRows,
  FourthRows,
  IconWrapper,
  PrimaryButton,
  SecondaryButton,
  SecondRows,
  StepsWrap,
  StyledMenu,
  StyledPhoneFormat,
  StyledSelect,
  StyledTextField,
  ThirdRows,
} from './account-form';
import Styles, { Typography } from '../../../../../../../../styles';
import Icons from '../../../../../../../../assets/icons';
import { IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import colors from '../../../../../../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import mock from '../../../../../../../../mock';
import {
  useGetDistrictQuery,
  useGetRegionQuery,
  useGetSchoolTypeQuery,
} from '../../../../../../../../store/services/authApi';
import { setModeratorStepOne } from '../../../../../../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import {
  useGetScienceByClassQuery,
  useGetScienceTypeQuery,
} from '../../../../../../../../store/services/scienceApi';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useMultiSelectControl, useWindowWidth } from '../../../../../../../../hooks';
import { truncateString } from '../../../../../../../../helpers/truncateString';
import { useGetClassGroupQuery } from '../../../../../../../../store/services/classApi';

interface IAccountFormProps {
  setPageState: (state: string) => void;
  pageState: string;
  setAccountForm: (state: boolean) => void;
}

const animationVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0 },
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'auth.userReg.errors.minName')
    .required('auth.userReg.errors.required'),
  lastName: Yup.string()
    .min(2, 'auth.userReg.errors.minName')
    .required('auth.userReg.errors.required'),
  province: Yup.string().required('auth.userReg.errors.required'),
  city: Yup.string().required('auth.userReg.errors.required'),
  institutionType: Yup.string().required('auth.userReg.errors.required'),
  specialty: Yup.string().required('auth.userReg.errors.required'),
  school: Yup.number().required('auth.userReg.errors.required'),
  class_group: Yup.number().required('auth.userReg.errors.required'),
  phoneNumber: Yup.string()
    .min(9, 'auth.userReg.errors.minNumber')
    .required('auth.userReg.errors.required'),
  password: Yup.string()
    .min(8, 'auth.userReg.errors.minPass')
    .matches(/[a-zA-Zа-яА-я]/, 'auth.userReg.errors.matches')
    .required('auth.userReg.errors.required'),
});

export const AccountForm: FC<IAccountFormProps> = ({ setPageState, pageState, setAccountForm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('global');
  const [showPassword, setShowPassword] = useState(true);
  const getRegion = useGetRegionQuery(i18n.language);
  const [district, setDistrict] = useState(1);
  const [classGrp, setClassGrp] = useState(0);
  const [scienceGrp, setScienceGrp] = useState(0);
  const windowWidth = useWindowWidth();
  const maxLength = windowWidth <= 575 ? 30 : windowWidth > 575 && windowWidth <= 630 ? 20 : 30;
  const getDistrict = useGetDistrictQuery({ region_id: district, lang: i18n.language });
  const { data: scienceData } = useGetScienceByClassQuery({
    id: classGrp || Number(localStorage.getItem('class_group')),
    lang: i18n.language,
  });
  const [eduType, setEduType] = useState("O'rta maxsus");
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const { data: schoolType } = useGetSchoolTypeQuery({
    educationType: eduType,
    lang: i18n.language,
  });
  const [scienceInfo, setScienceInfo] = useState(Number(localStorage.getItem('specialty')) || 2);
  const { data: scienceTypesData } = useGetScienceTypeQuery({
    class_group: classGrp || Number(localStorage.getItem('class_group')),
    science: scienceGrp || Number(localStorage.getItem('specialty')),
    lang: i18n.language,
  });

  const formik = useFormik({
    initialValues: {
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',
      province: Number(localStorage.getItem('province')) || 1,
      city: Number(localStorage.getItem('city')) || 5,
      class_group: Number(localStorage.getItem('class_group')) || 0,
      institutionType: localStorage.getItem('institutionType') || "O'rta maxsus",
      institutionNumber: localStorage.getItem('institutionNumber') || '',
      specialty: Number(localStorage.getItem('specialty')) || 0,
      school: Number(localStorage.getItem('school')) || 0,
      classes: JSON.parse(localStorage.getItem('science_types') || '[]'),
      phoneNumber: localStorage.getItem('phoneNumber') || '',
      password: localStorage.getItem('pass') || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setAccountForm(true);
      dispatch(
        setModeratorStepOne({
          first_name: values.firstName,
          last_name: values.lastName,
          region: values.province,
          district: Number(values.city),
          school: values.school,
          institution: values.institutionType,
          class_group: values.class_group,
          institution_number: values.institutionNumber,
          science: values.specialty,
          science_types: values.classes,
          phone: values.phoneNumber,
          password: values.password,
        })
      );
      setPageState('rating');
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getRegion;
        await getDistrict.refetch();
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, [getRegion]);

  useEffect(() => {
    setDistrict(formik.values.province);
  }, [formik.values.province]);

  useEffect(() => {
    if (getDistrict.data) {
      formik.setFieldValue('city', getDistrict.data.districts[0].id);
    }
  }, [getDistrict.data]);

  useEffect(() => {
    localStorage.setItem('firstName', formik.values.firstName);
    localStorage.setItem('lastName', formik.values.lastName);
    localStorage.setItem('province', formik.values.province.toString());
    localStorage.setItem('city', formik.values.city.toString());
    localStorage.setItem('school', formik.values.school.toString());
    localStorage.setItem('class_group', formik.values.class_group.toString());
    localStorage.setItem('institutionType', formik.values.institutionType);
    localStorage.setItem('institutionNumber', formik.values.institutionNumber);
    localStorage.setItem('specialty', formik.values.specialty.toString());
    localStorage.setItem('science_types', JSON.stringify(formik.values.classes));
    localStorage.setItem('phoneNumber', formik.values.phoneNumber);
    localStorage.setItem('pass', formik.values.password);
  }, [formik.values, scienceInfo]);

  useEffect(() => {
    localStorage.setItem('science_types', JSON.stringify([]));
  }, [scienceInfo]);

  const { openSelectId, open, close } = useMultiSelectControl();

  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <AccountFormWrap>
        <Styles.Row gap={26}>
          <StepsWrap>
            <Styles.Row gap={24} wrap={'nowrap'}>
              {mock.Steps.registration.map((item, index) => (
                <motion.div
                  key={index}
                  initial='hidden'
                  animate='visible'
                  variants={animationVariants}
                  transition={{ duration: 0.5 * index }}>
                  <Styles.Row wrap={'nowrap'}>
                    <Styles.Row gap={16} wrap={'nowrap'}>
                      <IconWrapper
                        onClick={() => setPageState(item.navigate)}
                        index={index}
                        content={'center'}
                        align_items={'center'}>
                        <item.icons
                          color={index === 0 ? colors.white : colors['typography-body']}
                        />
                      </IconWrapper>
                      <Styles.Row direction={'column'} width='123px'>
                        <Typography.h6 color='typography-body'>{t(item.label)}</Typography.h6>
                        <Typography.paragraphSmall color='typography-muted'>
                          {t(item.title)}
                        </Typography.paragraphSmall>
                      </Styles.Row>
                    </Styles.Row>
                    {item.chevron && (
                      <Styles.Row align_items={'center'}>
                        <Icons.ChevronRight />
                      </Styles.Row>
                    )}
                  </Styles.Row>
                </motion.div>
              ))}
            </Styles.Row>
          </StepsWrap>
          <Styles.Row gap={26}>
            <Styles.Row gap={6} direction={'column'}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.h3 color='typography-body'>{t('auth.userReg.title')}</Typography.h3>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.paragraph color='typography-muted'>
                  {t('auth.userReg.authorSubTitle')}
                </Typography.paragraph>
              </Styles.Row>
            </Styles.Row>
            <Styles.Row gap={16}>
              <FirstRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.name')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledTextField
                      placeholder='John'
                      name='firstName'
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    />
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.firstName && formik.errors.firstName && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.firstName)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.surname')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledTextField
                      placeholder='Doe'
                      name='lastName'
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    />
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.lastName && formik.errors.lastName && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.lastName)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
              </FirstRows>
              <SecondRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.province')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledSelect
                      name='province'
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      open={openSelectId === 'province'}
                      onOpen={() => open('province')}
                      onClose={close}
                      displayEmpty
                      error={formik.touched.province && Boolean(formik.errors.province)}
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '500px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      {getRegion?.data &&
                        getRegion?.data?.results.map((region: any, index: number) => (
                          <StyledMenu key={index} value={region.id}>
                            {truncateString(region.region, maxLength)}
                          </StyledMenu>
                        ))}
                    </StyledSelect>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.province && formik.errors.province && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.province)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.city')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledSelect
                      name='city'
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      displayEmpty
                      open={openSelectId === 'city'}
                      onOpen={() => open('city')}
                      onClose={close}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '500px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      {getDistrict?.data?.districts.map((district: any, index: number) => (
                        <StyledMenu key={index} value={district.id}>
                          {district.district}
                        </StyledMenu>
                      ))}
                    </StyledSelect>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.city && formik.errors.city && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.city)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
              </SecondRows>
              <ThirdRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.institutionName')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'></Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledTextField
                      placeholder={t('auth.userReg.inputs.institutionName')}
                      name='institutionNumber'
                      value={formik.values.institutionNumber}
                      onChange={formik.handleChange}
                    />
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}></Styles.Row>
                </Styles.Column>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.class_group')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledSelect
                      value={formik.values.class_group}
                      onChange={(event) => {
                        formik.handleChange(event);
                        setClassGrp(event.target.value as number);
                      }}
                      displayEmpty
                      open={openSelectId === 'class_group'}
                      onOpen={() => open('class_group')}
                      onClose={close}
                      renderValue={(selected: any) => {
                        if (selected === 0) {
                          return <>{t('auth.userReg.inputs.placeholder')}</>;
                        }

                        const selectedClassGroup = classGroupData?.results.find(
                          (group: any) => group.id === selected
                        );
                        return selectedClassGroup ? selectedClassGroup.name : '';
                      }}
                      name='class_group'
                      error={formik.touched.class_group && Boolean(formik.errors.class_group)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}>
                      {classGroupData?.results &&
                        classGroupData?.results.map((classGroup: any, index: number) => (
                          <StyledMenu key={index} value={classGroup.id}>
                            {classGroup.name}
                          </StyledMenu>
                        ))}
                    </StyledSelect>
                    {formik.touched.school && formik.errors.school && (
                      <Typography.paragraphSmall color='danger-600'>
                        {t(formik.errors.school)}
                      </Typography.paragraphSmall>
                    )}
                  </Styles.Row>
                </Styles.Column>
              </ThirdRows>
              <FourthRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.lesson')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledSelect
                      disabled={formik.values.class_group === 0}
                      name='specialty'
                      value={formik.values.specialty}
                      open={openSelectId === 'specialty'}
                      onOpen={() => open('specialty')}
                      onClose={close}
                      onChange={(event) => {
                        formik.handleChange(event);
                        setScienceGrp(event.target.value as number);
                      }}
                      displayEmpty
                      error={formik.touched.specialty && Boolean(formik.errors.specialty)}
                      renderValue={(selected: any) => {
                        if (formik.values.class_group === 0) {
                          return <>{t('auth.userReg.inputs.sciencePlaceholder')}</>;
                        }

                        const findScienceName = (id: number) => {
                          const science = scienceData?.results.find((item: any) => item.id === id);
                          return science ? science.name : '';
                        };

                        return selected ? (
                          findScienceName(selected)
                        ) : (
                          <>{t('auth.userReg.inputs.placeholder')}</>
                        );
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '500px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      {scienceData?.results &&
                        scienceData?.results.map((science: any, index: number) => (
                          <StyledMenu
                            key={index}
                            onClick={() => {
                              setScienceInfo(science.id);
                              formik.setFieldValue('classes', []);
                            }}
                            value={science.id}>
                            {science.name}
                          </StyledMenu>
                        ))}
                    </StyledSelect>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.specialty && formik.errors.specialty && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.specialty)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.grade')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledSelect
                      disabled={formik.values.specialty === 0}
                      name='classes'
                      value={formik.values.classes}
                      onChange={formik.handleChange}
                      open={openSelectId === 'classes'}
                      onOpen={() => open('classes')}
                      onClose={close}
                      multiple
                      displayEmpty
                      renderValue={(selected: any) => {
                        if (formik.values.specialty === 0) {
                          return <>{t('auth.userReg.inputs.scienceGrpPlaceholder')}</>;
                        } else {
                          const getSelectedNames = () => {
                            return selected
                              .map((id: number) => {
                                const science = scienceTypesData?.results.find(
                                  (item: any) => item.id === id
                                );
                                return science ? science.name : '';
                              })
                              .filter((name: string) => name !== '')
                              .join(', ');
                          };
                          return selected.length === 0 ? (
                            <>{t('auth.userReg.inputs.placeholder')}</>
                          ) : (
                            getSelectedNames()
                          );
                        }
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '500px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}
                      error={formik.touched.classes && Boolean(formik.errors.classes)}>
                      {scienceTypesData &&
                        scienceTypesData?.results.map((scienceInfo: any, index: number) => (
                          <StyledMenu key={index} value={scienceInfo.id}>
                            {scienceInfo.name}
                          </StyledMenu>
                        ))}
                    </StyledSelect>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.classes && formik.errors.classes && (
                      <Typography.paragraph color='danger-500'>
                        {/* {t(formik.errors.classes)} */}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
              </FourthRows>
              <FifthRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.phone')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledPhoneFormat
                      id='account phone number'
                      name='phoneNumber'
                      type='text'
                      format='+998 (##) ###-##-##'
                      allowEmptyFormatting
                      mask='_'
                      value={formik.values.phoneNumber}
                      onValueChange={(values) =>
                        formik.setValues({
                          ...formik.values,
                          phoneNumber: values.value,
                        })
                      }
                      errorColor={
                        Boolean(formik.errors.phoneNumber) && Boolean(formik.touched.phoneNumber)
                      }
                    />
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.phoneNumber)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
                <Styles.Column size={6} difference={0} gap={4}>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.passStart')}
                    </Typography.paragraphSmall>
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <StyledTextField
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('auth.userReg.inputs.passPlaceholder')}
                      name='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onFocus={() => setShowPassword(true)}
                      onBlur={() => setShowPassword(false)}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                setShowPassword((prevShowPassword) => !prevShowPassword)
                              }
                              onMouseDown={(e) => e.preventDefault()}>
                              {showPassword ? (
                                <Icons.Eye color={colors['typography-muted']} />
                              ) : (
                                <Icons.EyeOff color={colors['typography-muted']} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography.paragraphSmall>
                      {t('auth.userReg.inputs.passEnd')}
                    </Typography.paragraphSmall>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.password && formik.errors.password && (
                      <Typography.paragraph color='danger-500'>
                        {t(formik.errors.password)}
                      </Typography.paragraph>
                    )}
                  </Styles.Row>
                </Styles.Column>
              </FifthRows>
              <Styles.Row
                gap={10}
                wrap={'nowrap'}
                content={'space-between'}
                align_items={{ xs: 'center', sm: 'flex-start' }}>
                <SecondaryButton onClick={() => navigate('/author/select-user')}>
                  <Typography.paragraph color='primary-500'>
                    {t('auth.userReg.cancelBtn')}
                  </Typography.paragraph>
                </SecondaryButton>
                <PrimaryButton type='submit' variant='contained'>
                  <Typography.paragraph color='white'>
                    {t('auth.authorReg.rating.nextBtn')}
                  </Typography.paragraph>
                </PrimaryButton>
              </Styles.Row>
              <Styles.Row>
                <Typography.paragraph color='typography-muted'>
                  {t('auth.userReg.authorTitle')}
                </Typography.paragraph>
                <Typography.paragraph color='primary-500'>
                  <Link to={'/login'}>{t('auth.userReg.authorLink')}</Link>
                </Typography.paragraph>
              </Styles.Row>
            </Styles.Row>
          </Styles.Row>
        </Styles.Row>
      </AccountFormWrap>
    </form>
  );
};
