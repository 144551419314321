/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import {
  BtnWrap,
  ChatTitleWrapper,
  ChatWrapper,
  EmptyTitleWrapper,
  EmptyTypo,
  Line,
  PlanInfoWrapper,
  PlansViewWrap,
  PrimaryButton,
  PrimaryIconBtn,
  SecondaryIconBtn,
  StyledColumn,
  StyledMenu,
  StyledSelect,
  StyledTable,
  StyledTableContainer,
  StyledTextField,
  TableContentWrap,
} from './plans-view.s';
import * as Yup from 'yup';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../constants';
import Icons from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { formatBytes } from '../../../helpers/formatBytes';
import { useAppTheme } from '../../../hooks';
import { handleDownloadClick } from '../../../helpers/fileDownload';
import { useGetTopicByIdQuery } from '../../../store/services/moderatorPlansApi';
import { formatUpdatedDate } from '../../../helpers/formatUpdatedDate';
import { fullNameGenerator } from '../../../helpers/fullNameGenerator';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import mock from '../../../mock';
import { useAiQuestionAndAnswerMutation } from '../../../store/services/topicApi';

interface IPlansViewProps {}

type SetIsDownloading = (id: number | null) => void;

interface IFormData {
  topics: {
    type: string;
    question: string;
    answer: string;
  }[];
}

export const PlansView: FC<IPlansViewProps> = (props) => {
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const parts = currentUrl.split('/');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [aiData, { isLoading }] = useAiQuestionAndAnswerMutation();
  const id = parts[parts.length - 1];
  const questionMock = mock.questionType;

  const { data: topicData, refetch } = useGetTopicByIdQuery({
    id: Number(id),
    lang: i18n.language,
  });

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(
      id,
      name,
      i18n.language,
      setIsDownloading,
      setSnackbarMessage,
      setSnackbarOpen
    );
  };

  const getUniqueAuthors = (media: Array<any>) => {
    const authorsMap = new Map<number, string>();
    media.forEach((item) => {
      const { user } = item;
      if (user && user.first_name && user.last_name) {
        const fullName = `${user.first_name} ${user.last_name}`;
        authorsMap.set(user.id, fullName);
      }
    });
    return Array.from(authorsMap.values());
  };

  const uniqueAuthors = getUniqueAuthors(topicData?.media || []);
  const displayAuthorNames =
    uniqueAuthors.length > 1 ? uniqueAuthors.join(', ') : uniqueAuthors[0] || '';

  const initialValues: IFormData = {
    topics: [{ type: '', question: '', answer: '' }],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      topics: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('plans.viewAddItem.body.errors.global'),
          question: Yup.string().required('plans.viewAddItem.body.errors.global'),
          answer: Yup.string().required('plans.viewAddItem.body.errors.global'),
        })
      ),
    }),
    onSubmit: async (values) => {
      try {
        const topics = values.topics.map((topic) => ({
          question: topic.question,
          answer: topic.answer,
          type: topic.type,
          ...(topic.type !== 'ALL' && { topic: id }),
        }));
        const jsonData = JSON.stringify(topics);
        await aiData(jsonData).unwrap();
        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        setSnackbarOpen(true);
        formik.resetForm();
      } catch (error: any) {
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
    validateOnChange: true,
  });

  const getError = (index: number, field: keyof IFormData['topics'][0]) => {
    const errors = formik.errors.topics?.[index];
    return errors && typeof errors === 'object' && !Array.isArray(errors) ? errors[field] : '';
  };

  const getIconColor = (isDisabled: boolean, theme: string) => {
    if (isDisabled) {
      return theme === 'dark' ? colors['dark-theme-secondary'] : colors['grey-300']; // Цвет иконки для отключенной кнопки
    }
    return theme === 'dark' ? colors['grey-300'] : colors['typography-body'];
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <PlansViewWrap onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.loadingTitle')}
            </Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row gap={16}>
          <Styles.Row gap={4}>
            <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {topicData?.name}
            </Typography.h4>
          </Styles.Row>
          <Common.Container>
            <PlanInfoWrapper wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
              <Styles.Row direction={'column'} gap={16}>
                <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {topicData?.description}
                </Typography.paragraph>
                <Line />
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.Books
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('auth.userReg.inputs.lesson')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.plan_id?.science_types?.name}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.Graduate
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.grade')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.plan_id?.classes?.name}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.Grid
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.view.table.select.quater')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.plan_id?.quarter?.choices}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.ListNumbers
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.themeNumber')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.sequence_number}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.MathFunction
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.theme')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.name}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.Clock
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.duration')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.hours}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.EmptyUser
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.author')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {displayAuthorNames}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row gap={8} align_items={'center'}>
                  <Icons.RotateClockwise
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.updated')}:
                  </Typography.paragraph>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {formatUpdatedDate(topicData?.created_at)}
                  </Typography.paragraph>
                </Styles.Row>
              </Styles.Row>
            </PlanInfoWrapper>
          </Common.Container>

          {topicData?.media?.length === 0 ? (
            <Common.Container>
              <EmptyTitleWrapper
                gap={4}
                content={'center'}
                align_items={'center'}
                direction={'column'}>
                <EmptyTypo color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.viewItem.body.title.resEmpty')}
                </EmptyTypo>
                {topicData?.media_creatable && (
                  <PrimaryButton
                    onClick={() => navigate(`/topic-resource/${id}/`)}
                    variant='contained'>
                    <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                    <Icons.Plus />
                  </PrimaryButton>
                )}
              </EmptyTitleWrapper>
            </Common.Container>
          ) : (
            <Common.Container>
              <BtnWrap content={'space-between'} align_items={'center'}>
                <Typography.h4 color='typography-muted'>
                  {t('plans.view.table.headerRes')}{' '}
                </Typography.h4>
                <PrimaryButton
                  onClick={() => navigate(`/topic-resource/${id}/`)}
                  variant='contained'>
                  <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                  <Icons.Plus />
                </PrimaryButton>
              </BtnWrap>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          {t('resources.add.table.content.number')}
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle
                            align_items={'center'}
                            content={'space-between'}
                            wrap={'nowrap'}>
                            {t('resources.add.table.content.name')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.resourceType')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.author')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.size')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.download')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topicData?.media?.map((topic: any, index: number) => (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='left'>{topic?.name}</TableCell>
                          <TableCell align='left'>{t(topic?.desc)}</TableCell>
                          <TableCell align='left'>
                            {fullNameGenerator(topic?.user?.first_name, topic?.user?.last_name)}
                          </TableCell>
                          <TableCell align='left'>{formatBytes(topic?.size)}</TableCell>
                          <TableCell align='center'>
                            <Styles.Row content={'center'}>
                              {/* <Button onClick={() => navigate(`/plan-resource-view/${topic.id}`)}>
                                <Icons.Eye
                                  color={
                                    theme === 'dark'
                                      ? colors['grey-300']
                                      : colors['typography-body']
                                  }
                                />
                              </Button> */}
                              {isDownloading !== topic.id ? (
                                <Button
                                  onClick={() =>
                                    onDownloadClick(topic?.id, topic.name, setIsDownloading)
                                  }>
                                  <Icons.Download
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-300']
                                        : colors['typography-body']
                                    }
                                  />
                                </Button>
                              ) : (
                                <Button disabled>
                                  <Icons.Loader
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-100']
                                        : colors['typography-muted']
                                    }
                                  />
                                </Button>
                              )}
                            </Styles.Row>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
            </Common.Container>
          )}
        </Styles.Row>
      )}

      <Common.Container>
        <Styles.Row gap={16}>
          <ChatTitleWrapper>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.viewAddItem.body.resAdd.aiTitle')}
            </Typography.h4>
          </ChatTitleWrapper>
          <FormikProvider value={formik}>
            <FieldArray
              name='topics'
              render={({ push, remove }) => (
                <>
                  {formik.values.topics.map((topic, index) => (
                    <ChatWrapper key={index} wrap={{ xs: 'wrap', md: 'nowrap' }} gap={16}>
                      <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', md: 'row' }}>
                        <Styles.Row gap={8} wrap={{ xs: 'wrap', md: 'nowrap' }}>
                          <StyledColumn size={6} difference={0} gap={4}>
                            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                              <Typography.paragraphSmall
                                color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                                as={'label'}
                                subColor='danger-600'
                                htmlFor={`topics.${index}.name`}>
                                {t('plans.viewAddItem.body.topicAdd.questionInput.type')}
                                <span>*</span>
                              </Typography.paragraphSmall>
                            </Styles.Row>
                            <Styles.Row
                              content={{ xs: 'center', sm: 'flex-start' }}
                              direction={'column'}>
                              <StyledSelect
                                name={`topics.${index}.type`}
                                value={formik.values.topics[index].type}
                                onChange={formik.handleChange}
                                MenuProps={{
                                  disableScrollLock: true,
                                  sx: {
                                    height: '280px',
                                    '& .MuiPaper-root': {
                                      overflowY: 'auto',
                                      scrollbarWidth: 'thin',
                                      scrollbarColor: '#bdbdbd transparent',
                                    },
                                  },
                                }}>
                                {questionMock?.map((item: any) => (
                                  <StyledMenu value={item.value}>{t(item.label)}</StyledMenu>
                                ))}
                              </StyledSelect>
                              {formik.touched.topics?.[index]?.type && (
                                <Typography.paragraph color='danger-500'>
                                  {t(getError(index, 'type') || '')}
                                </Typography.paragraph>
                              )}
                            </Styles.Row>
                          </StyledColumn>

                          <StyledColumn size={6} difference={0} gap={4}>
                            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                              <Typography.paragraphSmall
                                color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                                as={'label'}
                                subColor='danger-600'
                                htmlFor={`topics.${index}.name`}>
                                {t('plans.viewAddItem.body.topicAdd.questionInput.question')}
                                <span>*</span>
                              </Typography.paragraphSmall>
                            </Styles.Row>
                            <Styles.Row
                              content={{ xs: 'center', sm: 'flex-start' }}
                              direction={'column'}>
                              <StyledTextField
                                placeholder='...'
                                name={`topics.${index}.question`}
                                value={formik.values.topics[index].question}
                                onChange={formik.handleChange}
                              />

                              {formik.touched.topics?.[index]?.question && (
                                <Typography.paragraph color='danger-500'>
                                  {t(getError(index, 'question') || '')}
                                </Typography.paragraph>
                              )}
                            </Styles.Row>
                          </StyledColumn>

                          <StyledColumn size={6} difference={0} gap={4}>
                            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                              <Typography.paragraphSmall
                                subColor='danger-600'
                                color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                {t('plans.viewAddItem.body.topicAdd.questionInput.answer')}
                                <span>*</span>
                              </Typography.paragraphSmall>
                            </Styles.Row>
                            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                              <StyledTextField
                                placeholder='...'
                                name={`topics.${index}.answer`}
                                value={formik.values.topics[index].answer}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.topics?.[index]?.answer && (
                                <Typography.paragraph color='danger-500'>
                                  {t(getError(index, 'answer') || '')}
                                </Typography.paragraph>
                              )}
                            </Styles.Row>
                          </StyledColumn>
                        </Styles.Row>
                      </Styles.Row>

                      <Styles.Row
                        width='auto'
                        wrap={'nowrap'}
                        content={'flex-end'}
                        gap={16}
                        align_items={'flex-end'}>
                        <SecondaryIconBtn
                          type='button'
                          variant='outlined'
                          onClick={() => {
                            if (formik.values.topics.length > 1) {
                              remove(index);
                            }
                          }}
                          disabled={formik.values.topics.length <= 1}>
                          <Icons.Trash
                            color={getIconColor(formik.values.topics.length <= 1, theme)}
                          />
                        </SecondaryIconBtn>
                        <PrimaryIconBtn
                          type='button'
                          variant='contained'
                          onClick={() => push({ name: '', description: '', hours: '' })}>
                          <Icons.Plus />
                        </PrimaryIconBtn>
                      </Styles.Row>
                    </ChatWrapper>
                  ))}
                </>
              )}
            />
          </FormikProvider>
          <ChatWrapper content={'flex-end'}>
            <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
              <Typography.h6 color='white'>{t('plans.viewAddItem.body.button.send')}</Typography.h6>
            </PrimaryButton>
          </ChatWrapper>
        </Styles.Row>
      </Common.Container>

      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </PlansViewWrap>
  );
};
