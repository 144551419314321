/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  FormWrap,
  StyledDiv,
  StyledTypo4,
  ThemeFormWrap,
  UserButtons,
  UserFormInput,
} from './doc-form.s';
import * as Yup from 'yup';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import colors from '../../../../../constants';
import Icons from '../../../../../assets/icons';
import { useAppTheme } from '../../../../../hooks';
import { useAuthUpdateMeMutation, useGetMeQuery } from '../../../../../store/services/authApi';
import { useFormik } from 'formik';
import { Common } from '../../../../../components';

interface IThemeFormProps {
  setFormStatus: (status: string) => void;
}

interface FormValues {
  userFile: File[];
}

export const DocForm: FC<IThemeFormProps> = ({ setFormStatus }) => {
  const [t, i18n] = useTranslation('global');
  const { theme, setTheme } = useAppTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { data: getMe, refetch } = useGetMeQuery(null);
  const [updateMe, { isLoading }] = useAuthUpdateMeMutation();
  const documentFile = getMe?.response_file || null;

  const formik = useFormik<FormValues>({
    initialValues: {
      userFile: [],
    },
    validationSchema: Yup.object().shape({
      userFile: Yup.array().min(1, 'auth.authorReg.rating.errors.file'),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        values.userFile.forEach((userFile, index) => {
          formData.append(`docs[${index}]`, userFile);
          formData.append(`description[${index}]`, 'static');
        });
        await updateMe(formData).unwrap();
        formik.resetForm();
        setSnackbarMessage('settings.snackbarDoc');
        setSnackbarOpen(true);
        refetch();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <ThemeFormWrap onSubmit={formik.handleSubmit}>
      <Styles.Row>
        <FormWrap gap={16}>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', md: 'row' }}>
            <Styles.Row>
              <UserFormInput align_items={'flex-start'}>
                <StyledTypo4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.doc.infoDoc')}:
                  <StyledDiv>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={
                        i18n.language === 'uz'
                          ? 'https://classcom.felixits.uz/static/doc/document_uz.pdf'
                          : 'https://classcom.felixits.uz/static/doc/document_ru.pdf'
                      }>
                      <Icons.FileText
                        width={28}
                        height={28}
                        viewBox='0 0 25 18'
                        color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                      />
                    </a>
                  </StyledDiv>
                </StyledTypo4>
              </UserFormInput>
            </Styles.Row>
            <Styles.Row>
              <UserFormInput align_items={'flex-start'}>
                <StyledTypo4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.doc.confirmedDoc')}:
                  {documentFile ? (
                    <a target='_blank' rel='noopener noreferrer' href={documentFile}>
                      <Icons.FileText
                        width={28}
                        height={28}
                        viewBox='0 0 25 18'
                        color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                      />
                    </a>
                  ) : (
                    <Icons.FileText
                      width={28}
                      height={28}
                      viewBox='0 0 25 18'
                      color={theme === 'dark' ? colors['dark-theme-secondary'] : colors['grey-100']}
                    />
                  )}
                </StyledTypo4>
              </UserFormInput>
            </Styles.Row>
          </Styles.Row>
          {getMe?.response_file === null && (
            <Styles.Row>
              <Common.Uploader
                icon={<Icons.Upload color={colors['typography-body']} />}
                type='file'
                title='settings.doc.uploaderTitle'
                requiredText='auth.authorReg.rating.uploader.subtitle'
                files={formik.values.userFile}
                setFiles={(files) => {
                  formik.setFieldValue('userFile', [
                    ...formik.values.userFile,
                    ...Array.from(files),
                  ]);
                }}
              />
              {formik.touched.userFile && formik.errors.userFile ? (
                <Typography.paragraph color='danger-500'>
                  {Array.isArray(formik.errors.userFile)
                    ? formik.errors.userFile.join(', ')
                    : t(formik.errors.userFile)}
                </Typography.paragraph>
              ) : null}
            </Styles.Row>
          )}
        </FormWrap>
        {getMe?.response_file === null && (
          <UserButtons gap={16} wrap={'nowrap'}>
            <Styles.Row width='130px'>
              <Button
                variant='text'
                onClick={handleReset}
                sx={{
                  width: '150px',
                  padding: '10px',
                  backgroundColor: `${colors['opacity-primary16']}`,
                }}>
                <Typography.paragraphSmallSemiBold color='primary-400'>
                  {t('settings.buttons.cancel')}
                </Typography.paragraphSmallSemiBold>
              </Button>
            </Styles.Row>
            <Styles.Row width='130px'>
              <Button
                type='submit'
                disabled={isLoading}
                variant='contained'
                sx={{
                  width: '150px',
                  padding: '10px',
                }}>
                <Typography.paragraphSmallSemiBold color='white'>
                  {t('settings.buttons.send')}
                </Typography.paragraphSmallSemiBold>
              </Button>
            </Styles.Row>
          </UserButtons>
        )}
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ThemeFormWrap>
  );
};
