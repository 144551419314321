/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import {
  LoginFormWrap,
  PrimaryButton,
  RowTitleWrapper,
  RowPhoneWrapper,
  RowPasswordWrapper,
  BtnWrapper,
  LinkWrapper,
  InputWrapper,
} from './login-form.s';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../../../../store/services/authApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setRole, setUser } from '../../../../../store/slices/authSlice';
import { setAccessToken, setRefToken } from '../../../../../helpers/token';
import { IconButton, InputAdornment } from '@mui/material';
import Icons from '../../../../../assets/icons';
import colors from '../../../../../constants';
import { useTranslation } from 'react-i18next';

interface ILoginFormProps {}

export const LoginForm: FC<ILoginFormProps> = (props) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(9, 'auth.logIn.errors.phone.min')
        .required('auth.logIn.errors.phone.required'),
      password: Yup.string()
        .min(8, 'auth.logIn.errors.password.min')
        .matches(/[a-zA-Zа-яА-я]/, 'auth.logIn.errors.password.matches')
        .required('auth.logIn.errors.password.required'),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const response = await login({
          phone: values.phoneNumber,
          password: values.password,
          lang: i18n.language,
        }).unwrap();
        if (response.user.role === 'moderator') {
          if (response.user.is_contracted) {
            setAccessToken(response.access);
            setRefToken(response.refresh);
            dispatch(
              setRole({
                access_token: localStorage.getItem('accessToken'),
                refresh_token: localStorage.getItem('refreshToken'),
                role: response.user.role,
              }),
              setUser({
                avatar: response.user.avatar,
                first_name: response.user.first_name,
                last_name: response.user.last_name,
                phone: response.user.phone,
                role: response.user.role,
              })
            );
            navigate('/');
          } else {
            setSnackbarMessage('auth.logIn.errors.phone.waiting');
            setSnackbarOpen(true);
          }
        } else {
          setAccessToken(response.access);
          setRefToken(response.refresh);
          dispatch(
            setRole({
              access_token: localStorage.getItem('accessToken'),
              refresh_token: localStorage.getItem('refreshToken'),
              role: response.user.role,
            }),
            setUser({
              avatar: response.user.avatar,
              first_name: response.user.first_name,
              last_name: response.user.last_name,
              phone: response.user.phone,
              role: response.user.role,
            })
          );
          navigate('/');
        }
      } catch (error: any) {
        setSnackbarMessage(error.data.detail);
        setSnackbarOpen(true);
      }
    },
  });

  return (
    <LoginFormWrap>
      <Styles.Row gap={26}>
        <RowTitleWrapper gap={6}>
          <Styles.Column size={12} difference={0}>
            <Typography.h3 color='typography-body'>{t('auth.logIn.title')}</Typography.h3>
          </Styles.Column>
          <Styles.Column size={12} difference={0}>
            <Typography.paragraph color='typography-body'>
              {t('auth.logIn.subTitle')}
            </Typography.paragraph>
          </Styles.Column>
        </RowTitleWrapper>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.handleSubmit();
            }
          }}>
          <Styles.Row gap={16}>
            <RowPhoneWrapper gap={4}>
              <Styles.Column size={12} difference={0}>
                <Typography.placeholderSm
                  as={'label'}
                  htmlFor='login phone number'
                  color='typography-body'>
                  {t('auth.logIn.numberTitle')}
                </Typography.placeholderSm>
              </Styles.Column>
              <InputWrapper size={12} difference={0}>
                <GlobalStyles.StyledPhoneFormat
                  id='login phone number'
                  name='phoneNumber'
                  type='text'
                  format='+998 (##) ###-##-##'
                  allowEmptyFormatting
                  mask='_'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.phoneNumber}
                  onValueChange={(values) =>
                    formik.setValues({
                      ...formik.values,
                      phoneNumber: values.value,
                    })
                  }
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.phoneNumber)}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </InputWrapper>
            </RowPhoneWrapper>
            <RowPasswordWrapper
              gap={4}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}>
              <Styles.Row>
                <Styles.Row content={'space-between'}>
                  <Typography.placeholderSm as={'label'} htmlFor='password' color='typography-body'>
                    {t('auth.logIn.passwordTitle')}
                  </Typography.placeholderSm>
                  <Link
                    to={'/reset-password'}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        console.log('worked');
                      }
                    }}>
                    <Typography.placeholderSm color='primary-500'>
                      {t('auth.logIn.forgotTitle')}
                    </Typography.placeholderSm>
                  </Link>
                </Styles.Row>
              </Styles.Row>
              <Styles.Column size={12} difference={0}>
                <GlobalStyles.StyledTextField
                  id='password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='*******'
                  onFocus={() => setShowPassword(true)}
                  onBlur={() => setShowPassword(false)}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()}>
                          {showPassword ? (
                            <Icons.LockOpen color={colors['typography-muted']} />
                          ) : (
                            <Icons.Lock color={colors['typography-muted']} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.errors.password && formik.touched.password ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.password)}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Column>
            </RowPasswordWrapper>
            <BtnWrapper content={'center'}>
              <PrimaryButton type='submit' variant='contained' fullWidth disabled={isLoading}>
                {isLoading ? (
                  <Icons.Loader />
                ) : (
                  <Typography.h6 color='white'>{t('auth.logIn.btn')}</Typography.h6>
                )}
              </PrimaryButton>
            </BtnWrapper>
            <LinkWrapper content={'center'} gap={6}>
              <Typography.placeholderDefault color='typography-body'>
                {t('auth.logIn.signInTitle')}
              </Typography.placeholderDefault>
              <Link to={'/author/select-user'}>
                <Typography.placeholderDefault color='primary-500'>
                  {t('auth.logIn.signInLink')}
                </Typography.placeholderDefault>
              </Link>
            </LinkWrapper>
          </Styles.Row>
        </form>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </LoginFormWrap>
  );
};
