import { FooterMock } from './footer';
import { Menu } from './menu';
import { Payment } from './payment';
import { Settings } from './settings';
import { Steps } from './steps';
import { usersHomeData } from './usersHomeData';
import { days } from './days';
import { plansMainData } from './plansData';
import { resourcesMock } from './userResourcesMock';
import { SelectUser } from './selectUser';
import { lessonHours } from './lessonHours';
import { classes } from './classes';
import { Month } from './month';
import { Degree } from './degree';
import { questionType } from './questionType';
import { resourceTypes } from './resourceTypes';



const mock = {
  FooterMock,
  Menu,
  Settings,
  Steps,
  Payment,
  usersHomeData,
  days,
  lessonHours,
  plansMainData,
  resourcesMock,
  SelectUser,
  classes,
  Month,
  Degree,
  questionType,
  resourceTypes,
};

export default mock;
