import { createTheme } from '@mui/material';
import { colors } from './colors';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      50: 'rgba(235, 243, 254, 1)',
      100: 'rgba(194, 216, 252, 1)',
      200: 'rgba(165, 198, 251, 1)',
      300: 'rgba(124, 171, 249, 1)',
      400: 'rgba(98, 155, 248, 1)',
      500: 'rgba(59, 130, 246, 1)',
      600: 'rgba(54, 118, 224, 1)',
      700: 'rgba(42, 92, 175, 1)',
      800: 'rgba(32, 72, 135, 1)',
      900: 'rgba(25, 55, 103, 1)',
    },
    grey: {
      50: 'rgba(249, 250, 251, 1)',
      100: 'rgba(242, 244, 247, 1)',
      200: 'rgba(234, 236, 240, 1)',
      300: 'rgba(208, 213, 221, 1)',
      400: 'rgba(152, 162, 179, 1)',
      500: 'rgba(102, 112, 133, 1)',
      600: 'rgba(71, 84, 103, 1)',
      700: 'rgba(52, 64, 84, 1)',
      800: 'rgba(29, 41, 57, 1)',
      900: 'rgba(16, 24, 40, 1)',
    },
  },
  typography: {
    fontSize: 16,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            fontSize: 16,
            height: '45px',
            color: 'var(--grey-500)',
            fontWeight: 400,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important'
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: 'var(--grey-500)',
          padding: '8px 16px',
          fontSize: '15px',
        },
        root: {
          borderRadius: '6px',
          
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '2px solid var(--primary-500)',
          },
        },
        icon: {
          color: 'var(--extra-dark)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '8px',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '100%',
          backgroundColor: 'var(--extra-white)',
          color: 'var(--gray-900)',
          padding: '8px 16px',
          marginBottom: '4px',
          borderRadius: '6px',
          transition: 'all 0.3s',
          '&:hover': {
            backgroundColor: 'var(--gray-100)',
            color: 'var(--gray-900)',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&.Mui-active': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&:not(.Mui-selected)': {
            backgroundColor: 'var(--opacitygray8)',
            color: 'var(--gray-900)',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .Mui-selected': {
            backgroundColor: 'var(--primary-500)',
            color: 'rgba(249, 250, 251, 1)',
          },
          '& .Mui-selected:hover': {
            backgroundColor: 'rgba(59, 130, 246, 1)',
            color: 'rgba(249, 250, 251, 1)',
          },
          '& .MuiPaginationItem-root:not(.Mui-selected)': {
            backgroundColor: 'rgba(102, 112, 133, 0.08)',
            color: 'rgba(16, 24, 40, 1)',
            '&:hover': {
              backgroundColor: 'rgba(102, 112, 133, 0.16)',
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      50: 'rgba(59, 130, 246, 0.1)',
      100: 'rgba(59, 130, 246, 0.2)',
      200: 'rgba(59, 130, 246, 0.3)',
      300: 'rgba(59, 130, 246, 0.4)',
      400: 'rgba(59, 130, 246, 0.5)',
      500: 'rgba(59, 130, 246, 0.6)',
      600: 'rgba(59, 130, 246, 0.7)',
      700: 'rgba(59, 130, 246, 0.8)',
      800: 'rgba(59, 130, 246, 0.9)',
      900: 'rgba(59, 130, 246, 1)',
    },
    grey: {
      50: 'rgba(33, 43, 54, 0.1)',
      100: 'rgba(33, 43, 54, 0.2)',
      200: 'rgba(33, 43, 54, 0.3)',
      300: 'rgba(33, 43, 54, 0.4)',
      400: 'rgba(33, 43, 54, 0.5)',
      500: 'rgba(33, 43, 54, 0.6)',
      600: 'rgba(33, 43, 54, 0.7)',
      700: 'rgba(33, 43, 54, 0.8)',
      800: 'rgba(33, 43, 54, 0.9)',
      900: 'rgba(33, 43, 54, 1)',
    },
    text: {
      primary: 'rgba(249, 250, 251, 1)',
    },
    background: {
      default: 'rgba(47, 51, 73, 1)',
      paper: 'rgba(47, 51, 73, 1)',
    },
  },
  typography: {
    fontSize: 16,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(47, 51, 73, 1) !important',
          
          '& .MuiOutlinedInput-root': {
            fontSize: 16,
            height: '45px',
            color: 'var(--grey-200)',
            fontWeight: 400,
          },
          '& .MuiOutlinedInput-input': {
            color: 'var(--grey-400)',
            
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          border: '1px solid rgba(67, 73, 104, 1)',
          borderRadius: '5px',
          color: 'var(--grey-400)',
          padding: '8px 16px',
          fontSize: '15px',
          background: 'rgba(47, 51, 73, 1)',
        },
        root: {
          backgroundColor: 'rgba(47, 51, 73, 1) !important',
          borderRadius: '6px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '2px solid var(--primary-500)',
          },
        },
        icon: {
          color: 'var(--grey-500)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '8px',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '100%',
          backgroundColor: 'var(--extra-white)',
          color: 'var(--gray-900)',
          padding: '8px 16px',
          marginBottom: '4px',
          borderRadius: '6px',
          transition: 'all 0.3s',
          '&:hover': {
            backgroundColor: 'var(--gray-100)',
            color: 'var(--gray-900)',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&.Mui-active': {
            backgroundColor: 'var(--primary-500)',
            color: 'var(--extra-white)',
          },
          '&:not(.Mui-selected)': {
            backgroundColor: 'var(--opacitygray8)',
            color: 'var(--gray-900)',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .Mui-selected': {
            backgroundColor: 'var(--primary-500)',
            color: 'rgba(249, 250, 251, 1)',
          },
          '& .Mui-selected:hover': {
            backgroundColor: 'rgba(59, 130, 246, 1)',
            color: 'rgba(249, 250, 251, 1)',
          },
          '& .MuiPaginationItem-root:not(.Mui-selected)': {
            backgroundColor: 'rgba(102, 112, 133, 0.08)',
            color: 'var(--grey-200)',
            '&:hover': {
              backgroundColor: 'rgba(102, 112, 133, 0.16)',
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(67, 73, 104, 1) !important',
          borderRadius: '0px !important',
          '&::-webkit-scrollbar': {
            width: '8px', 
          },
          '&::-webkit-scrollbar-thumb': {
            background: colors['grey-500'], 
            borderRadius: '8px', 
          },
          scrollbarWidth: 'thin',
          scrollbarColor: `${colors['grey-500']} ${colors['dark-theme-secondary']}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(67, 73, 104, 1) !important',
          backgroundColor: 'var(--dark-theme-secondary) !important',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: '1px solid rgba(67, 73, 104, 1)',
        },
        input: {
          color: 'var(--grey-300)',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'var(--grey-500)',
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'var(--grey-500)',
          '&.Mui-checked': {
            color: 'var(--primary-400)',
          }
        },
      }
    },
  },
});
