/* eslint-disable react/jsx-pascal-case */
import { FC, useState, createContext, useEffect } from 'react';
import { MainWrap, TypographyWrapper } from './main.s';
import {
  useGetScheduleByDateQuery,
  useGetSchedulesQuery,
} from '../../../../store/services/userScheduleApi';
import Styles, { Typography } from '../../../../styles';
import { TodayDate, getCurrentDate, getTodaySheduleDate } from '../../../../lib/getDay';
import { t } from 'i18next';
import { Diary, Header } from './components';
import { Common } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from '../../../../hooks';
import { WeekData } from '../../../../types/week';

interface IMainProps {}

type UserMainPropsContextType = {
  allowEdit: boolean;
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserMainProps = createContext<UserMainPropsContextType | undefined>(undefined);

export const Main: FC<IMainProps> = () => {
  const today = TodayDate();
  const todaySheduleDate = getTodaySheduleDate();
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const [weeksData, setWeeksData] = useState<WeekData[]>([]);
  const [scheduleQuarter, setScheduleQuarter] = useState<number | null>(null);
  const [scheduleWeek, setScheduleWeek] = useState<number | null>(null);
  
  const shouldFetch = scheduleQuarter !== null && scheduleWeek !== null;
  const { data: scheduleData, isLoading } = useGetSchedulesQuery(
    { quarter: scheduleQuarter, week: scheduleWeek, lang: i18n.language },
    { skip: !shouldFetch }
  );
  const [shedule, setShedule] = useState(scheduleData);
  const { data: scheduleTodayDate, isLoading: todayLoading } = useGetScheduleByDateQuery({
    date: todaySheduleDate,
    lang: i18n.language,
  });

  const dayIndexMapping: Record<string, number> = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
  };

  let daysToShow: any[][] = Array(6)
    .fill(null)
    .map(() => []);
  let isHoliday = false;

  const fillDaysToShow = (schedules: any[]) => {
    schedules.forEach((item: any) => {
      const index = dayIndexMapping[item.weekday];
      if (index >= 0 && index < daysToShow.length) {
        daysToShow[index].push(item); 
      }
    });
  };

  if (shedule?.results?.length > 0) {
    fillDaysToShow(shedule.results[0]?.schedule_template?.schedules || []);
    // isHoliday = shedule?.results?.[0]?.quarter === undefined;
  } else {
    fillDaysToShow(scheduleTodayDate?.results[0]?.schedule_template?.schedules || []);
    // isHoliday = scheduleTodayDate?.results?.[0]?.quarter === undefined;
  }

  const renderEmptyDays = () => {
    return Array(6)
      .fill(null)
      .map((_, index) => {
        const currentDate = getCurrentDate(index, scheduleWeek, weeksData);
        const date = `${currentDate.month} ${currentDate.day.toString().padStart(2, '0')} ${
          currentDate.year
        }`;
        return (
          <Styles.Column key={index} size={{ xs: 12, lg: 6 }} difference={8}>
            <Diary
              weekId={
                shedule !== undefined
                  ? shedule?.results?.[0]?.week?.id
                  : scheduleTodayDate?.results?.[0]?.week?.id
              }
              theme={theme}
              todayWeekday={today.weekday}
              weekday={index + 1}
              quarter={
                shedule !== undefined
                  ? shedule?.results?.[0]?.quarter?.id
                  : scheduleTodayDate?.results?.[0]?.quarter?.id
              }
              day={null}
              slot1={[]}
              slot2={[]}
              date={date}
              dateByIndex={`${currentDate.year}-${currentDate.monthIndex + 1}-${currentDate.day
                .toString()
                .padStart(2, '0')}`}
            />
          </Styles.Column>
        );
      });
  };

  const isSunday = today.weekday === 0;

  useEffect(() => {
    if (scheduleQuarter === null && scheduleWeek === null) {
      setShedule(undefined);
    }
  }, [scheduleQuarter, scheduleWeek]);

  useEffect(() => {
    setShedule(scheduleData);
  }, [scheduleData]);

  return (
    <MainWrap>
      <Header
        setWeeksData={setWeeksData}
        scheduleQuarter={scheduleQuarter}
        scheduleWeek={scheduleWeek}
        setScheduleQuarter={setScheduleQuarter}
        setScheduleWeek={setScheduleWeek}
        theme={theme}
        isHoliday={isHoliday}
        quarter={
          shedule !== undefined
            ? shedule?.results?.[0]?.quarter?.id
            : scheduleTodayDate?.results?.[0]?.quarter?.id
        }
      />
      <Styles.Row gap={16}>
        {isLoading || todayLoading ? (
          <Common.Container>
            <TypographyWrapper align_content={'center'} content={'center'}>
              <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                {t('plans.main.loadingTitle')}
              </Typography.h4>
            </TypographyWrapper>
          </Common.Container>
        ) : (
          <>
            {isHoliday || isSunday ? (
              <TypographyWrapper align_content={'center'} content={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                  {t('mainPage.holiday')}
                </Typography.h4>
              </TypographyWrapper>
            ) : (
              <Styles.Row gap={16}>
                {daysToShow.every((dayArray) => dayArray.length === 0)
                  ? renderEmptyDays()
                  : daysToShow.map((dayArray: any[], index: number) => {
                      const currentDate = getCurrentDate(index, scheduleWeek, weeksData);
                      const date = `${currentDate.month} ${currentDate.day
                        .toString()
                        .padStart(2, '0')} ${currentDate.year}`;
                      const dateByIndex = `${currentDate.year}-${
                        currentDate.monthIndex + 1
                      }-${currentDate.day.toString().padStart(2, '0')}`;
                      return (
                        <Styles.Column key={index} size={{ xs: 12, lg: 6 }} difference={8}>
                          <Diary
                            weekId={
                              shedule !== undefined
                                ? shedule?.results?.[0]?.week?.id
                                : scheduleTodayDate?.results?.[0]?.week?.id
                            }
                            theme={theme}
                            todayWeekday={today.weekday}
                            weekday={currentDate.weekday}
                            quarter={
                              shedule !== undefined
                                ? shedule?.results?.[0]?.quarter?.id
                                : scheduleTodayDate?.results?.[0]?.quarter?.id
                            }
                            day={dayArray.map((item) => item.id) || []}
                            slot1={dayArray.filter((item) => item.shift === 'MORNING')}
                            slot2={dayArray.filter((item) => item.shift === 'EVENING')}
                            date={date}
                            dateByIndex={dateByIndex}
                          />
                        </Styles.Column>
                      );
                    })}
              </Styles.Row>
            )}
          </>
        )}
      </Styles.Row>
    </MainWrap>
  );
};
